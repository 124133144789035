import request from '../_api/request'

const state = {
  banner: []
}

const mutations = {
  SET_BANNER (state, v) {
    state.banner = v || []
  }
}

const actions = {
  async BANNER_GET (context, query) {
    return request.get('feature/banner' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_BANNER', res.data.data || [])
      } else {
        context.commit('SET_BANNER', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async BANNER_PROCESS (context, params) {
    return request.post('feature/banner', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
