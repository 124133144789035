import request from '../_api/request'

const state = {
  trx: {
    data: [],
    total: 0
  },
  driverStatus: [
    { id: -1, name: 'All Status' },
    { id: 0, name: 'Ready to ship' },
    { id: 1, name: 'On Delivery' },
    { id: 2, name: 'Complete' },
    { id: 99, name: 'Pending' }
  ]
}

const mutations = {
  SET_TRX (state, v) {
    state.trx.data = v.data || []
    state.trx.total = parseInt(v.total) || 0
  }
}

const actions = {
  async TRX_GET (context, query) {
    return request.get('trx/all' + (query || '')).then((res) => {
      if (query && res.status) {
        if (query.match(/driver=/gi)) {
          context.commit('SET_TRX', res.data.data)
        }
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_GET_SUMMARY (context, query) {
    return request.get('trx/all/summary' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_GET_ONE (context, trxid) {
    return request.get('trx/detail/' + (trxid || '')).then(res => res).catch(e => e)
  },
  async TRX_TAKE_DRIVER (context, trxid) {
    return request.post('trx/take-driver/' + (trxid || '')).then(res => res).catch(e => e)
  },
  async TRX_GET_ITEMS (context, trxID) {
    return request.get('trx/all/' + (trxID || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_PROCESS (context, params) {
    return request.post('trx', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_UPDATE (context, params) {
    return request.put('trx', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_PAYMENT_GET (context, trxID) {
    return request.get('trx/payment/' + (trxID || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_PAYMENT_PROCESS (context, params) {
    return request.post('trx/payment', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_PAYMENT_DELETE (context, id) {
    return request.delete('trx/payment/' + id).then(res => res).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
