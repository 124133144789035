import Vue from 'vue'

const Auth = () => import(/* webpackChunkName: "auth" */ '@/components/Auth.vue')
const Toast = () => import(/* webpackChunkName: "toast" */ '@/components/Toast.vue')
const Tags = () => import(/* webpackChunkName: "tags" */ '@/components/Tags.vue')
const Map = () => import(/* webpackChunkName: "map" */ '@/components/Map.vue')
const DriverLayout = () => import(/* webpackChunkName: "driver-layout" */ '@/components/DriverLayout.vue')
const ScanBarcode = () => import(/* webpackChunkName: "scan-barcode" */ '@/components/ScanBarcode.vue')

Vue.component('Auth', Auth)
Vue.component('Toast', Toast)
Vue.component('Tags', Tags)
Vue.component('Map', Map)
Vue.component('DriverLayout', DriverLayout)
Vue.component('ScanBarcode', ScanBarcode)
