import request from '../_api/request'

const state = {
  product: {
    data: [],
    total: 0
  },
  stock_request: {
    data: [],
    total: 0
  },
  productTags: []
}

const mutations = {
  SET_PRODUCT (state, v) {
    state.product.data = v.data || []
    state.product.total = parseInt(v.total) || 0
  },
  SET_PRODUCT_TAGS (state, v) {
    state.productTags = v || []
  },
  SET_STOCK_REQUEST (state, v) {
    state.stock_request.data = v.data || []
    state.stock_request.total = parseInt(v.total) || 0
  }
}

const actions = {
  async PRODUCT_GET (context, query) {
    context.dispatch('PRODUCT_TAGS_GET')
    return request.get('product' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_PRODUCT', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_PRODUCT', { data: [], total: 0 })
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_GET_TOTAL (context, query) {
    return request.get('product/total' + (query || '')).then((res) => res).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_GET_ONE (context, pid) {
    return request.get('product/' + pid).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_TAGS_GET (context, query) {
    query = query ? query + '&store=' + context.rootState.storeActive : ('?store=' + context.rootState.storeActive)
    return request.get('product/tags' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_PRODUCT_TAGS', res.data.data)
      } else {
        context.commit('SET_PRODUCT_TAGS', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_TAGS_PROCESS (context, params) {
    return request.post('product/tags', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PROCESS (context, params) {
    return request.post('product/item', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PROCESS (context, params) {
    return request.post('product', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_GET (context, query) {
    return request.get('product/stock-request' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_STOCK_REQUEST', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_STOCK_REQUEST', { data: [], total: 0 })
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_GET_ONE (context, pid) {
    return request.get('product/stock-request/' + pid).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_PROCESS (context, params) {
    return request.post('product/stock-request/stock', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_PROCESS (context, params) {
    return request.post('product/stock-request', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
